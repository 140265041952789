import React from 'react';
import { ColumnLayout, Container, Header, Link as PolarisLink, Spinner } from '@amzn/awsui-components-react/polaris';
import { Table } from '@amzn/awsui-components-react';
import { renderElement, renderField, renderLink } from '../../Components/tableUtils';
import CopyToClipboardButton from '../../Components/copyToClipboardButton';
import { createIsengardLink, getDomainFromHost } from '../../../utils';
import { AppConfig } from '../../../api/types';
import useConfig from '../../../hooks/useConfig';
import { Broker, BrokerEngineType, EC2Instance, EC2Volume, Link } from '@amzn/amazonmq-opsconsole-client';

type Props = {
    broker: Broker,
}

const NetworkingTable : React.FC<Props> = ({broker}) => {

    const appConfig = useConfig();

    if (appConfig == undefined) {
        return <></>
    }

    const brokerInfo = broker.brokerInfo;

    let content : React.ReactElement[] = [];

    content.push(<Header>Nat Gateway Public IPV4 Addresses</Header>);
    if (brokerInfo.natGatewayPublicIpAddressList == undefined || brokerInfo.natGatewayPublicIpAddressList == null) {
        content.push(<div>No Public IPV4 Addresses found for this broker</div>);
    } else {
        brokerInfo.natGatewayPublicIpAddressList.map((a) => {
            content.push(<div>{a.publicIpv4Address}<br /></div>);
        });

        // brokerInfo.natGatewayPublicIpAddressList.map((a) => {
        //     content.push({a.publicIpv4Address}<br></br>)
        // }); // does not work
        // brokerInfo.natGatewayPublicIpAddressList.map((publicAddress) => {
        //     content.push({publicAddress.publicIpv4Address}<br></br>)
        // });
    }

    return (
        <Container header={
            <Header variant="h2">
                Networking
            </Header>
        }>
            <ColumnLayout columns={3} variant="text-grid">
                {content}
            </ColumnLayout>
        </Container>
    )
}

export default NetworkingTable;